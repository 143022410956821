import { Splide, EventInterface } from "@splidejs/splide";

export function DebrandTransition(Splide, Components) {
    const { bind } = EventInterface(Splide);
    const { Move } = Components;
    const { list } = Components.Elements;

    let endCallback;

    function mount() {
        bind(list, "transitionend", (e) => {
            if (e.target === list && endCallback) {
                // Removes the transition property
                cancel();

                // Calls the `done` callback
                endCallback();
            }
        });
    }

    function start(index, done) {
        // Converts the index to the position
        const destination = Move.toPosition(index, true);

        // Applies the CSS transition
        list.style.transition =
            "transform 1s cubic-bezier(0.645, 0.045, 0.355, 1)";

        // Moves the carousel to the destination.
        Move.translate(destination);

        // Keeps the callback to invoke later.
        endCallback = done;
    }

    function cancel() {
        list.style.transition = "";
    }

    return {
        mount,
        start,
        cancel,
    };
}

document.addEventListener("DOMContentLoaded", function () {
    let homepageSlider = document.querySelectorAll(".homepageBanner .splide");
    homepageSlider.forEach(function (element) {
        let interval = element.getAttribute("data-interval");
        let autoplay = parseInt(interval) ? true : false;

        var splide = new Splide(element, {
            type: "loop",
            mediaQuery: "min",
            perPage: 1,
            arrows: false,
            pagination: true,
            autoplay: autoplay,
            interval: interval ? interval : 6000,
            breakpoints: {
                600: {},
                1200: {},
                1800: {},
                2400: {},
                3000: {},
            },
        });

        splide.on("move", function (newIndex, oldIndex, destIndex) {
            // Restart all video slides that are not the current slide
            var slides = splide.Components.Elements.slides;
            slides.forEach(function (slide, index) {
                var video = slide.querySelector("video");
                if (video) {
                    if (index === newIndex) {
                        video.currentTime = 0;
                    }
                }
            });
        });

        splide.mount({}, DebrandTransition);
    });

    let gallerySlider = document.querySelectorAll(
        ".block.gallery.slider .splide"
    );
    gallerySlider.forEach(function (element) {
        new Splide(element, {
            type: "slide",
            mediaQuery: "min",
            gap: 32,
            start: 1,
            perPage: 1,
            padding: { left: 0, right: 48 },
            breakpoints: {
                600: {
                    perPage: 2,
                    padding: { left: 0, right: 64 },
                },
                1200: {
                    perPage: 3,
                    padding: { left: 0, right: 96 },
                },
                1800: {
                    perPage: 4,
                    padding: { left: 0, right: 128 },
                },
                2400: {
                    perPage: 5,
                },
                3000: {
                    perPage: 6,
                },
            },
            arrowPath:
                "M29.375,19.375 L12.1328127,19.375 L20.4375005,11.0703135 C20.6816411,10.8261728 20.6816411,10.4306245 20.4375005,10.1867199 C20.1933599,9.94281292 19.7978127,9.94257927 19.5539069,10.1867199 L10.1789069,19.5617187 C9.93476629,19.8058593 9.93476629,20.2014065 10.1789069,20.4453135 L19.5539069,29.8203135 C19.679687,29.937501 19.835937,30 20,30 C20.164063,30 20.3198433,29.9389601 20.4418755,29.8168755 C20.6860161,29.5727348 20.6860161,29.1771889 20.4418755,28.9332819 L12.1328127,20.625 L29.375,20.625 C29.7187495,20.625 30,20.3437495 30,20 C30,19.6562505 29.7187495,19.375 29.375,19.375 Z M20,0 C8.95312548,0 0,8.95312548 0,20 C0,31.0468745 8.95312548,40 20,40 C31.0468745,40 40,31.0468745 40,20 C40,8.95312548 31.0468745,0 20,0 Z M20,38.75 C9.66406226,38.75 1.25,30.3359365 1.25,20 C1.25,9.66406345 9.66406226,1.25 20,1.25 C30.3359365,1.25 38.75,9.66406345 38.75,20 C38.75,30.3359365 30.3359365,38.75 20,38.75 Z",
        }).mount();
    });

    let giftCardSlider = document.querySelectorAll(".step .gift-cards .splide");
    giftCardSlider.forEach(function (element) {
        new Splide(element, {
            type: "slide",
            mediaQuery: "min",
            gap: 64,
            start: 1,
            perPage: 1,
            breakpoints: {
                768: {
                    perPage: 2,
                    padding: 64,
                },
                1200: {
                    perPage: 3,
                },
            },
            arrowPath:
                "M29.375,19.375 L12.1328127,19.375 L20.4375005,11.0703135 C20.6816411,10.8261728 20.6816411,10.4306245 20.4375005,10.1867199 C20.1933599,9.94281292 19.7978127,9.94257927 19.5539069,10.1867199 L10.1789069,19.5617187 C9.93476629,19.8058593 9.93476629,20.2014065 10.1789069,20.4453135 L19.5539069,29.8203135 C19.679687,29.937501 19.835937,30 20,30 C20.164063,30 20.3198433,29.9389601 20.4418755,29.8168755 C20.6860161,29.5727348 20.6860161,29.1771889 20.4418755,28.9332819 L12.1328127,20.625 L29.375,20.625 C29.7187495,20.625 30,20.3437495 30,20 C30,19.6562505 29.7187495,19.375 29.375,19.375 Z M20,0 C8.95312548,0 0,8.95312548 0,20 C0,31.0468745 8.95312548,40 20,40 C31.0468745,40 40,31.0468745 40,20 C40,8.95312548 31.0468745,0 20,0 Z M20,38.75 C9.66406226,38.75 1.25,30.3359365 1.25,20 C1.25,9.66406345 9.66406226,1.25 20,1.25 C30.3359365,1.25 38.75,9.66406345 38.75,20 C38.75,30.3359365 30.3359365,38.75 20,38.75 Z",
        }).mount();
    });

    let featuredProductSlider = document.querySelectorAll(
        ".block.featuredProducts .splide"
    );
    featuredProductSlider.forEach(function (element) {
        new Splide(element, {
            type: "slide",
            mediaQuery: "min",
            gap: 18,
            start: 0,
            perPage: 1,
            padding: 16,
            breakpoints: {
                640: {
                    perPage: 2,
                    padding: 64,
                },
                768: {
                    perPage: 3,
                },
                1200: {
                    perPage: 4,
                    padding: 128,
                },
            },
            arrowPath:
                "M29.375,19.375 L12.1328127,19.375 L20.4375005,11.0703135 C20.6816411,10.8261728 20.6816411,10.4306245 20.4375005,10.1867199 C20.1933599,9.94281292 19.7978127,9.94257927 19.5539069,10.1867199 L10.1789069,19.5617187 C9.93476629,19.8058593 9.93476629,20.2014065 10.1789069,20.4453135 L19.5539069,29.8203135 C19.679687,29.937501 19.835937,30 20,30 C20.164063,30 20.3198433,29.9389601 20.4418755,29.8168755 C20.6860161,29.5727348 20.6860161,29.1771889 20.4418755,28.9332819 L12.1328127,20.625 L29.375,20.625 C29.7187495,20.625 30,20.3437495 30,20 C30,19.6562505 29.7187495,19.375 29.375,19.375 Z M20,0 C8.95312548,0 0,8.95312548 0,20 C0,31.0468745 8.95312548,40 20,40 C31.0468745,40 40,31.0468745 40,20 C40,8.95312548 31.0468745,0 20,0 Z M20,38.75 C9.66406226,38.75 1.25,30.3359365 1.25,20 C1.25,9.66406345 9.66406226,1.25 20,1.25 C30.3359365,1.25 38.75,9.66406345 38.75,20 C38.75,30.3359365 30.3359365,38.75 20,38.75 Z",
        }).mount();
    });

    let productCollectionsSlider = document.querySelectorAll(
        ".block.productCollections .splide"
    );
    productCollectionsSlider.forEach(function (element) {
        new Splide(element, {
            type: "slide",
            mediaQuery: "min",
            gap: 18,
            start: 1,
            perPage: 1,
            padding: 16,
            breakpoints: {
                640: {
                    perPage: 2,
                    padding: 64,
                },
                768: {
                    perPage: 3,
                },
                1200: {
                    perPage: 4,
                    padding: 128,
                },
            },
            arrowPath:
                "M29.375,19.375 L12.1328127,19.375 L20.4375005,11.0703135 C20.6816411,10.8261728 20.6816411,10.4306245 20.4375005,10.1867199 C20.1933599,9.94281292 19.7978127,9.94257927 19.5539069,10.1867199 L10.1789069,19.5617187 C9.93476629,19.8058593 9.93476629,20.2014065 10.1789069,20.4453135 L19.5539069,29.8203135 C19.679687,29.937501 19.835937,30 20,30 C20.164063,30 20.3198433,29.9389601 20.4418755,29.8168755 C20.6860161,29.5727348 20.6860161,29.1771889 20.4418755,28.9332819 L12.1328127,20.625 L29.375,20.625 C29.7187495,20.625 30,20.3437495 30,20 C30,19.6562505 29.7187495,19.375 29.375,19.375 Z M20,0 C8.95312548,0 0,8.95312548 0,20 C0,31.0468745 8.95312548,40 20,40 C31.0468745,40 40,31.0468745 40,20 C40,8.95312548 31.0468745,0 20,0 Z M20,38.75 C9.66406226,38.75 1.25,30.3359365 1.25,20 C1.25,9.66406345 9.66406226,1.25 20,1.25 C30.3359365,1.25 38.75,9.66406345 38.75,20 C38.75,30.3359365 30.3359365,38.75 20,38.75 Z",
        }).mount();
    });
});
